import Config from '../Config'
import CryptoJS from 'crypto-js'


/**
 * 
 * Contains general function for the web app
 * 
 */
export default class Auth {  
  /**
 * 
 * The function logs in a user
 * 
 */
  login = (frmLogin) => {
    const config = new Config()

    const $http = config.DConnect()
    $http.get('web/web/validate/'  + frmLogin.email + '/' + frmLogin.pwd)
    .then(res => {
      let islogin = this.decryptData(res.data)
      
      if (islogin) {
        $http.get('admin/web/user/' + frmLogin.email)
        .then(res => {
            let user = this.decryptData(res.data)

          $http.get('admin/web/' + user.username + '/' + user.token + '/role/'  + user.auth)
          .then(res => {
            let role = this.decryptData(res.data)

            this.setToken(user.token)
            this.setUser(user.username)
            this.setFeatures(role.features)
            this.setRole(role.rolename)

            window.location.reload(true)
          }).catch((error)=>{alert(error)})
        }).catch((error)=>{alert(error)})
      }else {
        alert('Problem login')
      }
    }).catch((error)=>{alert('Validate: ' + error)})
  }

  /**
 * 
 * Displays automatic logout message
 * 
 */
  doAlert = (status) => {
    let msg = (status === 601) ? "You have been logged out by the system..." 
    : "The system is in lock mode, please try again later..."
    alert(msg)
    this.logout()
    window.location.reload(true)
  }

  /**
 * 
 * Check if a user has been authenticated
 * 
 */
  isAuth = () => {
    return this.loggedIn()
  }

/**
 * 
 * Checks if a user is logged in
 * 
 */
  loggedIn = () => {
    return !!this.getUser()
  }

  /**
 * 
 * Saves a user token received
 * 
 */
  setToken = (idToken) => {
    sessionStorage.setItem('alpha_token', idToken)
  }

  /**
 * 
 * retrieves a saved token
 * 
 */
  getToken = () => {
    return sessionStorage.getItem('alpha_token')
  }

  /**
 * 
 * saves the login user role
 * 
 */
  setRole = (rolename) => {
    sessionStorage.setItem('alpha_rolename', rolename)
  }
  
  /**
 * 
 * Get the login user role
 * 
 */
  getRole = () => {
    return sessionStorage.getItem('alpha_rolename')
  }

  /**
 * 
 * saves the login user information
 * 
 */
  setUser = (user) => {
    sessionStorage.setItem('alpha_user', user)
  }

  /**
 * 
 * get the login user information
 * 
 */
  getUser = () => {
    return sessionStorage.getItem('alpha_user')
  }

  /**
 * 
 * Sets the current login's operator
 * 
 */
  setOperator = (code) => {
    sessionStorage.setItem('alpha_operator', code)
  }

  /**
 * 
 * Get the current logins operator
 * 
 */
  getOperator = () => {
    return sessionStorage.getItem('alpha_operator')
  }

  /**
 * 
 * Set information about the company
 * 
 */
  setCompanySetup = (company) => {
    sessionStorage.setItem('zcompanysetup', company)
  }

  /**
 * 
 * gets the saved company information
 * 
 */
  getCompanySetup = () => {
    return sessionStorage.getItem('zcompanysetup')
  }

  /**
 * 
 * logs out a user
 * 
 */
  logout = () => {
    sessionStorage.removeItem('alpha_token')
    sessionStorage.removeItem('alpha_user')
    sessionStorage.removeItem('alpha_operator')
    sessionStorage.removeItem('features')
    sessionStorage.removeItem('SysDate')
    sessionStorage.removeItem('alpha_rolename')
  }

  /**
 * 
 * gets the private key used for encryption
 * 
 */
  getKey = () => {
    return 'mw2JjyMEQw0='
  }

  /**
 * 
 * Encrypts data
 * 
 */
  encryptData = (dt) => {
    var keyHex = CryptoJS.enc.Base64.parse(this.getKey())
    var encrypted = CryptoJS.DES.encrypt(dt, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString()
  }

  /**
 * 
 * Decrypt data
 * 
 */
  decryptData = (dt) => {
    var keyHex = CryptoJS.enc.Base64.parse(this.getKey())
    var decrypted = CryptoJS.DES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(dt)
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
  }

  /**
 * 
 * Decrypt text data
 * 
 */
  decryptText = (dt) => {
    var keyHex = CryptoJS.enc.Base64.parse(this.getKey())
    var decrypted = CryptoJS.DES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(dt)
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  /**
 * 
 * Saves the user previleges
 * 
 */
  setFeatures = (userole) => {
    sessionStorage.setItem('features', userole)
  }

  /**
 * 
 * gets the user previleges
 * 
 */
  getFeatures = () => {
    return sessionStorage.getItem('features')
  }

  /**
 * 
 * Sets the system date
 * 
 */
  setSysDate = (SysDate) => {
    sessionStorage.setItem('SysDate', SysDate)
  }

  /**
 * 
 * get the system date
 * 
 */
  getSysDate = () => {
    return sessionStorage.getItem('SysDate')
  }

  /**
 * 
 * Sets the current cart items
 * 
 */
  setCart = (cart) => {
    sessionStorage.setItem('alphaCart', cart)
  }

  /**
 * 
 * gets the saved cart items
 * 
 */
  getCart = () => {
    //sessionStorage.removeItem('alphaCart')
    return JSON.parse(sessionStorage.getItem('alphaCart'))
  }

  /**
 * 
 * Clears saved cart items
 * 
 */
  unsetCart = () => {
    sessionStorage.removeItem('alphaCart')
  }
}
