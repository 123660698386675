import React, { Component } from "react"
import ReactDOM from 'react-dom'
import Auth from "../common/Auth"
import Config from "../Config"
import moment from 'moment'
import _ from 'lodash'
import axios from 'axios'

import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2})
const lineb = { borderBottom:'1px solid #cccccc', padding:'5px'}
const linehb = {borderBottom:'1px solid #cccccc', padding:'5px', fontSize:'14px', fontWeight:'bold'}
const linebl = {borderBottom:'1px solid #cccccc', borderLeft:'1px solid #cccccc', padding:'5px'}
const linehbl = {borderBottom:'1px solid #cccccc', borderLeft:'1px solid #cccccc', padding:'5px', fontSize:'14px', fontWeight:'bold'}
const linebr = { borderBottom:'1px solid #cccccc', borderRight:'1px solid #cccccc', padding:'5px'}
const linehbr = { borderBottom:'1px solid #cccccc', borderRight:'1px solid #cccccc', padding:'5px', fontSize:'14px', fontWeight:'bold'}
const title = {
    border:'1px solid #000000',
    textAlign:'center',
    padding:'5px',
    fontSize:'18px',
    fontWeight:'bold'
}
const heading = {
    fontSize:'14px',
    fontWeight:'bold'
}

/**
 * 
 * Displays agent payout list
 * 
 */
export default class AgentPayout extends Component{
    constructor(props) {
        super(props)
    
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: { username: this.auth.getUser(), startDate: moment(),endDate: moment(), curuser: this.auth.getUser()},
            startDate: moment(),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            retailers: [],
            games: []
        }
    }

    componentDidMount() {
        this.getGames()
    }

    getRetailers = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/writers')
        .then(res => {
            let retailers = this.auth.decryptData(res.data)

            this.setState({ retailers })

            this.getReportData()
        })
    }

    getGames = () =>{
        this.$http.get('web/web/game')
        .then(res => {
            let games = this.auth.decryptData(res.data)
            
            this.setState({ games })

            this.getRetailers()
        })
    }

    getReportData = () => {
        ReactDOM.render('Loading...', document.getElementById('agentPayoutInner'))
        let formItems = this.state.formItems

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'web/web/agentpayout'))
        .then((res) => {
            if (isNaN(res.data)) {
                let resdata = this.auth.decryptData(res.data)

                let tickets = _.groupBy(resdata, 'retailer')
                let agentPayout = {}
                Object.keys(tickets).map(tick => {
                    let groups = _.groupBy(tickets[tick], 'game')

                    agentPayout[tick] = _.map(groups, function(value, key) {
                        return {
                            game: key,
                            gross:  _.reduce(value, function(total, o) { 
                                return total += o.gross;
                            }, 0),
                            wins:  _.reduce(value, function(total, o) { 
                                return total += o.wins;
                            }, 0)
                        }
                    })
                })
                
                this.setState({ agentPayout })
                ReactDOM.render(this.showTableData(agentPayout), 
                    document.getElementById('agentPayoutInner'))
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error); this.setState({ loader: false });})
    }

    retailerFormat=(cell)=>{
        let retailer = this.state.retailers.filter(ret => ret.username == cell)
        let retname = (retailer[0] !== undefined) 
        ? retailer[0].firstname + ' ' + retailer[0].lastname + ' (' + cell + ')' : cell

        return retname
    }    

    gameFormat = (cell, row) => {
        let game = this.state.games.filter(ga => ga.id == cell)
        let gamename = (game[0] !== undefined) ? game[0].gamename   : cell

        return gamename
    }

    agentFormat = (cell, row) => cell

    showTableData = (agentPayout) => {
        let formItems = this.state.formItems
        
        let itm = []
        let totalGross = 0
        let totalNets = 0
        let totalWins = 0

        Object.keys(agentPayout).map(key => {
            let lineGross = 0, lineNets = 0, lineWins = 0

            itm.push(<tr><td></td><td></td><td></td><td></td><td></td><td></td></tr>)
            itm.push(<tr><td></td><td colspan="5" style={title}>{this.retailerFormat(key)}</td></tr>)
            itm.push(<tr><td></td><td></td>
            <td width="120px" className="text-right" style={linehbl}>Gross</td>
            <td width="120px" className="text-right" style={linehb}>Nets</td>
            <td width="120px" className="text-right" style={linehb}>Wins</td>
            <td width="120px" className="text-right" style={linehbr}>Pay In/Out</td></tr>)

            agentPayout[key].map(rdata => {
                let net = rdata.gross - (rdata.gross * .2)
                let win = (isNaN(rdata.wins)) ? 0.00 : rdata.wins
                let pay = net - win
                let payinout = (pay < 0) ? '(' + numformat.format(Math.abs(pay)) + ')' : numformat.format(pay)

                totalGross += rdata.gross
                totalNets += net
                totalWins += win
                lineGross += rdata.gross
                lineNets += net
                lineWins += win

                itm.push(<tr><td className="table-bordered">{this.gameFormat(rdata.game, rdata)}</td>
                <td></td>
                <td className="text-right" style={lineb}>{numformat.format(rdata.gross)}</td>
                <td className="text-right" style={lineb}>{numformat.format(net)}</td>
                <td className="text-right" style={lineb}>{numformat.format(win)}</td>
                <td className="text-right" style={linebr}>
                    {(pay < 0) ? <b className="text-danger">{payinout}</b> : <b className="text-success">{payinout}</b>}
                </td></tr>)
            })

            let linepay = lineNets - lineWins
            let linepayinout = (linepay < 0) ? '(' + numformat.format(Math.abs(linepay)) + ')' : numformat.format(linepay)
            itm.push(<tr><td className="table-bordered text-right" style={heading}>Line Total: </td>
            <td></td>
                <td className="text-right" style={linehb}>{numformat.format(lineGross)}</td>
                <td className="text-right" style={linehb}>{numformat.format(lineNets)}</td>
                <td className="text-right" style={linehb}>{numformat.format(lineWins)}</td>
                <td className="text-right" style={linehbr}>
                    {(linepay < 0) ? <b className="text-danger">{linepayinout}</b> : <b className="text-success">{linepayinout}</b>}
                </td></tr>)
            itm.push(<tr><td colSpan="6">&nbsp;</td></tr>)
        })

        let totals = {gross:totalGross, wins:totalWins, net:totalNets}
        this.setState({ totals, tbStruct: itm })

        return(
            <div className="row">
                <div className="col-lg-12 bg-primary text-center text-white" style={{paddingTop:"5px",fontSize:"18px",textTransform:"uppercase"}}>
                    Agent Payout for {this.agentFormat(this.auth.getUser())}
                </div>
                <div className="col-lg-12"><hr className="hrsp" /></div>
                <div className="col-lg-12">
                    <table id="" className="table table-condensed table-responsive table-striped">
                        <tbody>
                            <tr>
                                <td width="20%" align="right">Operator: </td>
                                <td width="30%" ><b>Alpha</b></td>
                                <td width="20%" align="right">Total Gross: </td>
                                <td width="30%"><b>{numformat.format(totalGross)}</b></td>
                            </tr>
                          
                            <tr>
                                <td align="right">Start Date: </td>
                                <td ><b>{this.state.startDate.format('MMMM D, YYYY')}</b></td>
                                <td align="right">Total Wins: </td><td width="150px"><b>{numformat.format(totalWins)}</b></td>
                            </tr>
                            <tr>
                                <td align="right">End Date: </td>
                                <td ><b>{this.state.endDate.format('MMMM D, YYYY')}</b></td>
                                <td align="right">Total Net: </td><td width="150px"><b>{numformat.format(totalNets)}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-lg-12"><hr className="hrsp" /></div>

                <div className="col-lg-12" id="games">
                    <table className="table table-responsive table-condensed">{itm}</table>
                </div>
            </div>
        )
    }

    handleEvent = (event, picker) => {
        let formItems = this.state.formItems

        formItems['startDate'] = picker.startDate
        formItems['endDate'] = picker.endDate
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            formItems
        });

        this.getReportData()
    }

    render() {
        let start = this.state.startDate.format('D MMM, YYYY')
        let end = this.state.endDate.format('D MMM, YYYY')
        let label = start + ' - ' + end
        if (start === end) {label = start}
    
        return (
            <div class="blog-details-wrapper">
                <h3 class="post-title">My Payouts </h3>
                <hr />
                <div class="content">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="btn-group btn-group-sm pull-right">
                                <button className="btn btn-default">DateRange: </button>
                                <DatetimeRangePicker
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    ranges={this.state.ranges}
                                    onEvent={this.handleEvent}
                                    className="selected-date-range-btn btn btn-default">
                                        <div className="pull-left">
                                            <i className="fa fa-calendar" />&nbsp; &nbsp; 
                                            <span>{label}</span>
                                        </div>
                                        <div className="pull-right">
                                            &nbsp; &nbsp;<i className="fa fa-angle-down" />
                                        </div>
                                </DatetimeRangePicker>
                                {this.state.isAgent &&
                                <button className="btn btn-default">Retailer: </button>}
                                {this.state.isAgent &&
                                <select id="" name="operator" onChange={this.handleChange} value={this.state.formItems.retailer} className="btn btn-default" style={{ width: '200px', height: '30px' }}>
                                    {this.state.retailerList.map(retailer =>
                                        <option value={retailer.username}>{retailer.firstname} {retailer.lastname}</option>
                                    )}
                                </select>}
                                <button onClick={this.displayData} className="btn btn-info">Display</button>
                            </div>
                            <div className="clearfix"></div>
                            <hr />
                        </div>
                        <div className="col-lg-12" id="agentPayoutInner"></div>
                    </div>
                </div>
            </div>
        )
    }
}