import React, { Component } from 'react';
import Config from '../Config';
import Auth from './Auth';
import moment from 'moment'

/**
 * 
 * Footer template for all the pages
 * 
 */
export default class Footer extends Component{

    constructor(){
        super()

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            companySetup:{
                companyName:'', address:'', phone:'', email:'', autologout:5, logo:'', mobileApi:'',
                curuser:this.auth.getUser(), token:this.auth.getToken()
            }
        }
    }

    componentDidMount(){
        this.getCompanySetup()
    }

    getCompanySetup = () => {
        this.$http.get('admin/web/setup')
        .then(res => {
            let companySetup = this.auth.decryptData(res.data)
            
            this.setState({ companySetup })
        }).catch((error)=>{console.log(error.message)})
    }

    render(){
        return(
           <div>

    <footer class="footer-section">
      <div class="footer-top border-top border-bottom has_bg_image" data-background="assets/images/bg-four.jpg" style={{backgroundImage:'url(assets/images/bg-four.jpg)'}}>
        
        <div class="footer-top-second">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6">
                <div class="footer-widget widget-about">
                  <h3 class="widget-title">About Alpha</h3>
                  <ul class="footer-list-menu">
                    <li><a href="/about">About us</a></li>
                    <li><a href="/how-to-play">How it Works</a></li>
                    <li><a href="/faqs">FAQs</a></li>
                    <li><a href="/contact">Contacts</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6">
                <div class="footer-widget widget-links">
                  <h3 class="widget-title">Quick links</h3>
                  <ul class="footer-list-menu">
                    <li><a href="/terms_conditions">Terms &amp; Conditions</a></li>
                    <li><a href="/privacy_policy">Privacy Policy</a></li>
                    <li><a href="/disclaimer">Disclaimer</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="footer-widget widget-subscribe">
                  <div class="subscribe-part">
                    <a href="#" target="_blank"><img src="assets/images/android_badge_web.png" style={{height:'80px'}} /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-6 col-sm-7">
              <div class="copy-right-text">
                <p>© 2017-{moment().format('YYYY')} <a href="#"> <span>  Alpha Lotto Management</span></a> - All Rights Reserved. </p>
              </div>
            </div>
            <div class="col-lg-6 col-sm-5">
              <ul class="footer-social-links d-flex justify-content-end">
                <li><a href="https://web.facebook.com/alpha" target="_blank"><i class="fa fa-facebook"></i></a></li>
                <li><a href="https://twitter.com/alpha" target="_blank"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#"><i class="fa fa-youtube"></i></a></li>
                <li><a href="https://www.instagram.com/alpha/" target="_blank"><i class="fa fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
        )
    }
  }